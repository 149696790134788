<template>
  <a-page-header @back="() => $router.go(-1)" title="PADRÓN NOMINAL" class="pt-0 px-0">
    <template #extra>
      <div class="flex flex-wrap items-center gap-3">
        <a-breadcrumb>
          <a-breadcrumb-item><router-link to="/menu">Proyecto</router-link></a-breadcrumb-item>
          <a-breadcrumb-item>Padrón nominal</a-breadcrumb-item>
        </a-breadcrumb>
      </div>
    </template>
  </a-page-header>

  <div class="flex flex-wrap mb-4">
    <fieldset class="border border-solid w-full border-gray-300 p-3 px-8 md:mt-0 lg:w-auto">
      <legend class="text-sm">Filtros</legend>
      <PadronNominalFiltros @reloadFetch="reloadFetch" />
    </fieldset>

    <fieldset class="border border-solid w-full border-gray-300 p-3 px-8 md:mt-0 lg:w-auto">
      <legend class="text-sm">Acciones</legend>

      <button @click="applyExportar" class="text-center">
        <div class="text-3xl text-green-700">
          <FileExcelOutlined />
        </div>
        <p>Exportar</p>
      </button>
    </fieldset>
  </div>

  <a-spin tip="Descargando Reporte..." :spinning="spinning">
    <div class="max-w-full px-4 py-4 mx-auto overflow-x-auto">
      <a-table
        :columns="columns"
        :data-source="data"
        size="small"
        :pagination="false"
        rowKey="dni"
        :loading="isLoading"
        :rowClassName="
          (record, index) =>
            index % 2 === 0 ? 'cursor-pointer bg-white' : 'cursor-pointer bg-gray-100'
        "
      >
        <template #rangepages="{ index }">
          <span> {{ (curretPage - 1) * 10 + index + 1 }} </span>
        </template>

        <template #sexo="{ text: sexo }">
          <span>
            <a-tag :color="sexo === '1' ? 'blue' : 'pink'">
              {{ sexo === "1" ? "Hombre" : "Mujer" }}
            </a-tag>
          </span>
        </template>
      </a-table>
    </div>
  </a-spin>

  <footer class="flex flex-wrap lg:flex-nowrap items-center w-full justify-between pt-5 border-t-2">
    <a-pagination v-model:current="curretPage" v-model:pageSize="pageSize" :total="totalDocs" />
  </footer>
</template>

<script>
import { ref, watch, onMounted } from "vue";
import { columns } from "./utilsPadronNominal";
import { useStore } from "vuex";
import { FileExcelOutlined } from "@ant-design/icons-vue";
import PadronNominalApi from "@/api/padron_nominal";
import PadronNominalFiltros from "./PadronNominalFiltros.vue";
import ApiDescargas from "@/api/descargas";

export default {
  components: {
    PadronNominalFiltros,
    FileExcelOutlined
  },
  setup() {
    const store = useStore();
    const spinning = ref(false);
    const data = ref([]);
    const isLoading = ref(false);
    const curretPage = ref(1);
    const pageSize = ref(10);
    const totalDocs = ref(null);

    const fetchData = (currentPage = 1) => {
      isLoading.value = true;
      const search = store.getters["padron_nominal/search"];
      const establecimiento = store.getters["padron_nominal/establecimiento"];
      const distrito = store.getters["padron_nominal/distrito"];

      PadronNominalApi.getAllPadronNominal({ currentPage, search, establecimiento, distrito })
        .then((response) => {
          console.log(response.data);
          data.value = response.data.docs;
          totalDocs.value = response.data.totalDocs;
        })
        .catch((err) => console.log(err))
        .finally(() => (isLoading.value = false));
    };

    watch(curretPage, () => {
      fetchData(curretPage.value);
    });

    const reloadFetch = () => {
      curretPage.value = 1;
      fetchData(curretPage.value);
    };

    onMounted(() => {
      fetchData();
    });

    const applyExportar = () => {
      spinning.value = true;
      const search = store.getters["padron_nominal/search"];
      const establecimiento = store.getters["padron_nominal/establecimiento"];
      const distrito = store.getters["padron_nominal/distrito"];

      ApiDescargas.getReportePadronNominal(
        { search, establecimiento, distrito },
        "padron_nominal",
        "REPORTE PADRÓN NONIMAL"
      )
        .then(() => {})
        .catch((err) => {
          spinning.value = false;
        });
    };

    return {
      spinning,
      reloadFetch,
      curretPage,
      data,
      isLoading,
      pageSize,
      totalDocs,
      columns,
      applyExportar
    };
  }
};
</script>
